var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('a-carousel',{attrs:{"autoplay":""}},_vm._l(([0, 1, 2]),function(featuredPos){return _c('div',{key:featuredPos,staticClass:"outer",style:(_vm.getValueFromSource(_vm.service, `featured_images[${featuredPos}].path`)
          ? `background: url(${_vm.getValueFromSource(
              _vm.service,
              `featured_images[${featuredPos}].path`
            )})`
          : '')},[(
          !_vm.getValueFromSource(_vm.service, `featured_images[${featuredPos}].path`)
        )?_c('p',{staticStyle:{"color":"white","font-weight":"bold"}},[_vm._v(" No Image ")]):_vm._e()])}),0),_c('div',{staticClass:"service-details-wrapper"},[_c('div',{staticClass:"service-details"},[_c('h6',{staticClass:"service-label"},[_vm._v(_vm._s(_vm.serviceTitle))]),_c('h6',{staticClass:"service-text"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.getValueFromSource(_vm.service, "title"),28))+" ")])]),_c('div',{staticClass:"service-details"},[_c('h6',{staticClass:"service-label"},[_vm._v("Price")]),_c('h6',{staticClass:"service-text price-wrapper"},[_vm._v(" "+_vm._s(_vm.formatAmount(_vm.getValueFromSource(_vm.service, "price")))+" ")])])]),_c('p',{staticClass:"text-truncate text-muted mx-2"},[_vm._v(" "+_vm._s(_vm.getValueFromSource(_vm.service, "description"))+" ")]),_c('div',{staticClass:"service-details-wrapper"},[_c('div',{staticStyle:{"display":"flex","flex-flow":"row wrap"}},[(_vm.canUpdateService)?_c('div',{staticClass:"service-details cursor-pointer",staticStyle:{"margin-right":"10px"},on:{"click":_vm.onEditServiceClicked}},[_c('span',{staticClass:"fe fe-edit",staticStyle:{"color":"green","margin-right":"5px"}}),_c('span',{staticStyle:{"color":"black"}},[_vm._v("Edit")])]):_vm._e(),(_vm.canRemoveService)?_c('div',{staticClass:"service-details cursor-pointer",on:{"click":_vm.onEditRemoveClicked}},[_c('span',{staticClass:"fe fe-x-circle",staticStyle:{"color":"red","margin-right":"5px"}}),_c('span',{staticStyle:{"color":"black"}},[_vm._v("Delete")])]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }